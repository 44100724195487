<script lang="ts" setup>
import { useGetDataProductSelected } from '../hooks/useGetDataProductSelected';
import type { ProductSelectedProp } from '../type';
import IconSource from './IconSource.vue';
import ContentProductSelected from './ContentProductSelected.vue';
import ProductSourceSkeleton from '../../product/components/ProductSourceSkeleton.vue';

const props = defineProps<ProductSelectedProp>();
const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const {
  titleProduct,
  productsLengthCollection,
  collectionTitle,
  titleRelated,
  lineClampClass,
  isShowProductExcludeLength,
  productsExcludeLength,
  classContainerProductSelected,
} = useGetDataProductSelected(props);
</script>
<template>
  <ProductSourceSkeleton v-if="isLoading" />
  <div v-else>
    <div v-if="isNoProductInStore" :class="classContainerProductSelected">
      <img
        class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
        src="../../images/collection-thumbnail.png"
        alt="product feature img" />
      <p class="text-light-200 font-regular text-12 line-clamp-1 leading-[14px]">No product founded</p>
      <IconSource :is-loading="isLoading" @refresh="emit('refresh')" />
    </div>
    <div v-else>
      <div v-if="productSrcType === 'PickProduct'" :class="classContainerProductSelected">
        <img
          v-if="list.length === 1 && list?.[0].image"
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          :src="list?.[0].image"
          alt="product feature img" />
        <img
          v-else-if="list.length > 1"
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          src="../../images/multi-products.png"
          alt="product feature img" />
        <img
          v-else
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          src="../../images/collection-thumbnail.png"
          alt="product feature img" />
        <ContentProductSelected
          :is-show-sub-title="false"
          :title="titleProduct"
          :line-clamp-class="{ 'line-clamp-2': list.length === 1 }" />
        <IconSource :is-loading="isLoading" @refresh="emit('refresh')" />
      </div>
      <div v-else-if="productSrcType === 'Collection'" :class="classContainerProductSelected">
        <img
          v-if="collectionDisplay?.image"
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          :src="collectionDisplay?.image"
          alt="collection feature img" />
        <img
          v-else
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          src="../../images/collection-thumbnail.png"
          alt="collection feature img" />
        <ContentProductSelected
          :is-show-sub-title="!!collectionDisplay?.id"
          :title="collectionTitle"
          :sub-title="productsLengthCollection" />
        <IconSource :is-loading="isLoading" @refresh="emit('refresh')" />
      </div>
      <div v-else :class="classContainerProductSelected">
        <img
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          src="../../images/collection-thumbnail.png"
          alt="related feature img" />
        <ContentProductSelected
          :is-show-sub-title="isShowProductExcludeLength"
          :title="titleRelated"
          :sub-title="productsExcludeLength"
          :line-clamp-class="lineClampClass" />
        <IconSource :is-loading="isLoading" @refresh="emit('refresh')" />
      </div>
    </div>
  </div>
</template>
